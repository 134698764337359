import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ee0e294"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full flex flex-col justify-start items-center" }
const _hoisted_2 = {
  class: "w-full h-48",
  ref: "cropImageBoxElement"
}
const _hoisted_3 = ["id", "src"]
const _hoisted_4 = { class: "flex justify-center items-center mt-3 w-2/3 flex-shrink-0" }
const _hoisted_5 = ["max"]
const _hoisted_6 = { class: "flex justify-center w-full flex-shrink-0 mt-5 gap-x-10 h-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_minus = _resolveComponent("icon-minus")!
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        id: 'crop-image-' + _ctx.id,
        class: "block max-w-full h-full",
        ref: 'cropImageElement',
        src: _ctx.file,
        alt: ""
      }, null, 8, _hoisted_3)
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "h-5 w-5 flex justify-center items-center flex-shrink-0 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoomValue > 0.1 ? ((_ctx.zoomValue -= 0.2), _ctx.zoom()) : ''))
      }, [
        _createVNode(_component_icon_minus, { class: "mr-3 flex-shrink-0" })
      ]),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["w-full h-2 rounded-lg", `bg-${_ctx.zoomBarColor}`]),
        type: "range",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.zoomValue) = $event)),
        max: _ctx.maxZoom,
        min: "0",
        step: "0.1",
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zoom()))
      }, null, 42, _hoisted_5), [
        [_vModelText, _ctx.zoomValue]
      ]),
      _createElementVNode("div", {
        class: "h-5 w-5 flex justify-center items-center flex-shrink-0 cursor-pointer",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.zoomValue < _ctx.maxZoom ? ((_ctx.zoomValue += 0.2), _ctx.zoom()) : ''))
      }, [
        _createVNode(_component_icon_plus, { class: "ml-2 flex-shrink-0" })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "declineButton", { removeImage: _ctx.removeImage }, () => [
        _createVNode(_component_custom_button, {
          class: "flex-grow",
          id: _ctx.id,
          isPlainButton: true,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeImage()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.declineButtonText), 1)
          ]),
          _: 1
        }, 8, ["id"])
      ], true),
      _renderSlot(_ctx.$slots, "submitButton", { saveImage: _ctx.saveImage }, () => [
        _createVNode(_component_custom_button, {
          class: "flex-grow",
          id: _ctx.id,
          isStandardButton: true,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveImage()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.submitButtonText), 1)
          ]),
          _: 1
        }, 8, ["id"])
      ], true)
    ])
  ]))
}